/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div>
    <b-overlay
      :show="!pageIsLoaded"
      rounded="sm"
    >
      <b-row>
        <b-col md="9">
          <b-card>
            <!-- BODY -->
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormObserver"
            >
              <!-- Form -->
              <b-form

                @submit.prevent="handleSubmit(saveData)"
                @reset.prevent="resetForm"
              >

                <!-- Firma ad -->
                <validation-provider
                  #default="validationContext"
                  :name="$t('Firma')"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('Firma')"
                    label-for="customer_id"
                  >
                    <vue-autosuggest
                      :suggestions="customerSuggest.filteredCustomers"
                      :input-props="customerSuggest.inputProps"
                      :state="getValidationState(validationContext)"
                      :value="orderData.customer_name"
                      @selected="customerSelected"
                      @input="customerOnInputChanged"
                    >
                      <template slot-scope="{suggestion}">
                        <div class="d-flex align-items-center">

                          <div class="detail ml-50">
                            <b-card-text class="mb-0">
                              {{ suggestion.item.name }}
                            </b-card-text>
                            <small class="text-muted">
                              {{ suggestion.item.id }}
                            </small>
                          </div>
                        </div>
                      </template>
                    </vue-autosuggest>

                  </b-form-group>

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <b-badge
                    v-if="orderData.customer_id!=0"
                    variant="light-warning"
                  >
                    {{ $t('Müşteri Limiti: ') }}{{ orderData.customer_limit }}
                  </b-badge>
                </validation-provider>
                <!-- Satış Temsilcisi -->

                <!-- Satış Temsilcisi -->
                <b-form-group
                  v-if="usersData"
                  :label="$t('Satış Temsilcisi')"
                  label-for="user_id"
                >
                  <v-select
                    id="user_id"
                    v-model="orderData.order_user"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="usersData"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="user_id"
                  />
                </b-form-group>
                <b-card-body class="form-item-section">
                  <div
                    ref="form"
                    class="repeater-form"
                  >
                    <b-row
                      v-for="(item, index) in orderData.products"

                      :key="index"
                      ref="row"
                      class="pb-2"
                    >

                      <!-- Item Form -->
                      <!-- ? This will be in loop => So consider below markup for single item -->
                      <b-col
                        v-if="!item.is_deleted"
                        cols="12"
                      >

                        <!-- Form Input Fields OR content inside bordered area  -->
                        <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                        <div class="d-flex border rounded">
                          <b-row class="flex-grow-1">
                            <!-- Single Item Form Headers -->
                            <b-col
                              cols="12"
                              lg="12"
                            >
                              <b-form-group
                                :label="$t('Ürün')"
                                label-for="product"
                              >
                                <vue-autosuggest
                                  :suggestions="productSuggest.filteredProducts"
                                  :input-props="productSuggest.inputProps"
                                  :value="item.product_name"
                                  @selected="productSelected($event,index)"
                                  @input="productOnInputChanged"
                                >
                                  <template slot-scope="{suggestion}">
                                    <div class="d-flex align-items-center">
                                      <b-avatar :src="suggestion.item.image" />
                                      <div class="detail ml-50">
                                        <b-card-text class="mb-0">
                                          {{ suggestion.item.name }}
                                        </b-card-text>
                                        <small class="text-muted">
                                          Stok:{{ suggestion.item.stock }}   Rezerve:{{ suggestion.item.stock_reserved }}
                                        </small>
                                      </div>
                                    </div>
                                  </template>
                                </vue-autosuggest>
                              </b-form-group>
                            </b-col>
                            <b-col
                              cols="12"
                              lg="4"
                            >
                              <b-form-group
                                :label="$t('Miktar')"
                                label-for="quantity"
                              >
                                <b-input-group id="quantity">
                                  <b-form-input
                                    v-model="item.quantity"
                                    :label="$t('Miktar')"
                                  />
                                  <b-input-group-append is-text>
                                    {{ item.unit_name }}

                                  </b-input-group-append>
                                </b-input-group>

                              </b-form-group>
                            </b-col>
                            <b-col
                              cols="12"
                              lg="4"
                            >
                              <b-form-group
                                :label="$t('Liste Fiyatı')"
                                label-for="list_price"
                              >
                                <b-form-input
                                  id="list_price"
                                  readonly
                                  :value="item.list_price+' '+item.list_price_currency"
                                  :label="$t('Lise Fiyatı')"
                                />

                              </b-form-group>
                            </b-col>
                            <b-col
                              cols="12"
                              lg="4"
                            >   <b-form-group
                              :label="$t('Birim Fiyatı')"
                              label-for="unit_price"
                            >

                              <b-input-group id="unit_price">
                                <cleave
                                  id="unit_price"
                                  v-model="item.unit_price"
                                  size="lg"
                                  class="form-control"
                                  right
                                  :raw="false"
                                  :options="priceInputConfig"
                                />
                                <b-input-group-append>
                                  <b-form-select
                                    v-model="item.currency_id"
                                    :options="currencyOptions"
                                    @change="productCurrencyChanged(item)"
                                  />
                                </b-input-group-append>
                              </b-input-group>
                            </b-form-group>
                            </b-col>
                            <b-col
                              cols="12"
                              lg="6"
                            >
                              <b-form-group
                                :label="$t('Depo')"
                                label-for="store"
                              >
                                <v-select
                                  id="store"
                                  v-model="item.store_id"
                                  :reduce="store => store.store_id"
                                  :clearable="false"
                                  label="store_name"
                                  :options="item.stores"
                                />

                              </b-form-group>
                            </b-col>
                            <b-col
                              lg="6"
                              cols="12"
                            >
                              <b-form-group
                                :label="$t('Açıklama')"
                                label-for="description_1"
                              >
                                <b-form-input
                                  id="description_1"
                                  v-model="item.description"
                                  :label="$t('Açıklama')"
                                />

                              </b-form-group>
                            </b-col>

                          </b-row>
                          <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                            <feather-icon
                              size="16"
                              icon="XIcon"
                              class="cursor-pointer"
                              @click="deleteProduct(index)"
                            />

                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="d-flex align-items-center justify-content-end">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      size="sm"
                      variant="primary"
                      @click="addProduct"
                    >
                      {{ $t('Ürün Ekle') }}
                    </b-button>
                  </div>
                </b-card-body>

                <!-- Form Actions -->
                <div class="d-flex mt-2">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                  >
                    {{ $t('Kaydet') }}
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="button"
                    variant="outline-secondary"
                  >
                    {{ $t('Vazgeç') }}
                  </b-button>
                </div>

              </b-form>
            </validation-observer>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card
            class="card-transaction"
            no-body
          >
            <b-card-header>
              <b-card-title>{{ $t('Döviz Kurları') }}</b-card-title>

            </b-card-header>

            <b-card-body>
              <div
                v-for="(item, index) in currencyOptions.filter(a=>a.is_main==false)"
                :key="index"
                class="transaction-item"
              >
                <b-media no-body>

                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ item.text }}
                    </h6>
                    <small />
                  </b-media-body>
                </b-media>
                <div
                  class="font-weight-bolder text-success"
                >
                  {{ item.currency }}
                </div>
              </div>

            </b-card-body>
          </b-card>
          <b-card
            class="card-transaction"
            no-body
          >
            <b-card-header>
              <b-card-title>{{ $t('Toplam Fiyatlar') }}</b-card-title>

            </b-card-header>

            <b-card-body>
              <div
                v-for="(item, index) in totalPrices"
                :key="index"
                class="transaction-item"
              >
                <b-media no-body>

                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ item.currency_name }}
                    </h6>
                    <small />
                  </b-media-body>
                </b-media>
                <div
                  class="font-weight-bolder text-success"
                >
                  {{ item.price.toFixed(2)+" "+item.currency_name }}
                </div>
              </div>
              <hr>
              <div
                class="transaction-item"
              >
                <b-media no-body>

                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ $t('Toplam') }}({{ totalPriceCurrencyConverted.mainCurrency.text }})
                    </h6>

                  </b-media-body>
                </b-media>
                <div
                  class="font-weight-bolder"
                >
                  {{ totalPriceCurrencyConverted.totalPrice.toFixed(2) }} {{ totalPriceCurrencyConverted.mainCurrency.text }}
                </div>
              </div>
              <div
                class="transaction-item"
              >
                <b-media no-body>

                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ $t('Kdv') }} ({{ totalPriceCurrencyConverted.mainCurrency.text }})
                    </h6>

                  </b-media-body>
                </b-media>
                <div
                  class="font-weight-bolder"
                >
                  {{ totalPriceCurrencyConverted.totalTax.toFixed(2) }} {{ totalPriceCurrencyConverted.mainCurrency.text }} ({{ totalPriceCurrencyConverted.taxNames }})
                </div>
              </div>
              <div
                class="transaction-item"
              >
                <b-media no-body>

                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ $t('Kdv Dahil Toplam') }} ({{ totalPriceCurrencyConverted.mainCurrency.text }})
                    </h6>

                  </b-media-body>
                </b-media>
                <div
                  class="font-weight-bolder"
                >
                  {{ totalPriceCurrencyConverted.totalPriceWithTax.toFixed(2) }} {{ totalPriceCurrencyConverted.mainCurrency.text }}
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row></b-overlay>

  </div>
</template>

<script>
import {
  BInputGroup, BInputGroupAppend, BOverlay, BCardText, BBadge, BAvatar, BCard, BFormSelect, BRow, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import router from '@/router'
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'

import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
import { VueAutosuggest } from 'vue-autosuggest'
import ordersStoreModule from '../ordersStoreModule'

export default {
  components: {
    BRow,
    BAvatar,
    BCard,
    BCardText,
    BBadge,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormInvalidFeedback,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BInputGroup,
    VueAutosuggest,
    BInputGroupAppend,
    vSelect,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BOverlay,
    Cleave,
    BMedia,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      required,

      productSuggest: {
        limit: 10,
        filteredProducts: [],
        inputProps: {
          id: 'autosuggest__input',
          class: 'form-control',
          placeholder: "Ürün Adı Giriniz'",

        },

      },
      customerSuggest: {
        limit: 10,
        filteredCustomers: [],
        inputProps: {
          id: 'customer_id',
          class: 'form-control',
          placeholder: 'Firma Adı Giriniz',

        },

      },
    }
  },
  computed: {
    totalPrices() {
      const prices = []
      this.orderData.products.filter(pd => !pd.is_deleted).forEach(val => {
        let isVal = false
        prices.forEach(pval => {
          if (val.currency_id === pval.currency_id) {
            // eslint-disable-next-line no-param-reassign
            pval.price += (parseFloat(val.unit_price.replace(',', '')) * parseFloat(val.quantity))
            isVal = true
          }
        })
        if (!isVal && val.currency_id !== 0) {
          // eslint-disable-next-line eqeqeq
          const currency = this.currencyOptions.filter(item => item.value === val.currency_id)[0]

          prices.push({ currency_name: currency.text, currency_id: val.currency_id, price: parseFloat(val.unit_price.replace(',', '')) * parseFloat(val.quantity) })
        }
      })

      return prices
    },
    totalPriceCurrencyConverted() {
      const taxNames = []
      let convertedPriceAll = 0
      let taxAll = 0
      let totalPriceWithTaxAll = 0
      const mainCurrency = this.currencyOptions.filter(item => item.is_main === 1)[0]
      this.orderData.products.filter(pd => !pd.is_deleted).forEach(val => {
        const productCurrency = this.currencyOptions.filter(item => item.value === val.currency_id)[0]
        const totalPrice = val.unit_price.replace(',', '') * val.quantity
        const convertedPrice = (totalPrice * productCurrency.currency) * mainCurrency.currency
        // eslint-disable-next-line no-mixed-operators
        const tax = (convertedPrice * val.tax_rate / 100)
        const totalPriceWithTax = convertedPrice + tax
        convertedPriceAll += convertedPrice
        taxAll += tax
        totalPriceWithTaxAll += totalPriceWithTax
        const filteredTaxName = taxNames.filter(item => item === val.tax_name)
        if (filteredTaxName.length <= 0) {
          taxNames.push(val.tax_name)
        }
      })
      return {
        mainCurrency,
        totalTax: taxAll,
        totalPrice: convertedPriceAll,
        totalPriceWithTax: totalPriceWithTaxAll,
        taxNames: taxNames.join('+'),
      }
    },
  },
  methods: {
    productCurrencyChanged(item) {
      this.currencyOptions.forEach(val => {
        // eslint-disable-next-line eqeqeq
        if (item.currency_id === val.value) {
          // eslint-disable-next-line no-param-reassign
          item.currency_name = val.text
          // eslint-disable-next-line no-param-reassign
          item.exchange_rate = val.currency
        }
      })
    },
    deleteProduct(index) {
      this.$swal({
        title: this.$t('Emin Misiniz ?'),
        text: this.$t('Bu Ürün Kalemini Silmek İstediğinizden Emin Misiniz ? (Bu işlem geri alınamaz.)'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Silme İşlemini Onayla'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const pdata = this.orderData.products[index]
          if (pdata.id === 0) {
            this.orderData.products.splice(index, 1)
          } else {
            this.orderData.products[index].is_deleted = true
          }
        }
      })
    },
    saveData() {
      let ifZeroId = false
      let errors = false
      if (this.orderData.order_user <= 0) {
        this.$bvToast.toast(this.$t('Temsilci Seçiniz. Temsilci alanı boş bırakılamaz'), {
          title: this.$t('Dikkat'),
          variant: 'warning',
          solid: false,
        })
        return
      }
      if (this.orderData.customer_id <= 0) {
        this.$bvToast.toast(this.$t('Lütfen firma seçimi yapınız'), {
          title: this.$t('Dikkat'),
          variant: 'warning',
          solid: false,
        })
        return
      }
      this.orderData.products.forEach(val => {
        if (val.store_id === 0) {
          errors = true
          this.$bvToast.toast(this.$t('Eklediğiniz bazı ürün satırlarında depo seçimi yapmadınız'), {
            title: this.$t('Dikkat'),
            variant: 'warning',
            solid: false,
          })
          ifZeroId = true
          // eslint-disable-next-line no-useless-return
        }
        if (val.product_id === 0) {
          errors = true
          this.$bvToast.toast(this.$t('Eklediğiniz bazı ürün satırlarında ürün seçimi yapmadınız'), {
            title: this.$t('Dikkat'),
            variant: 'warning',
            solid: false,
          })
          ifZeroId = true
          // eslint-disable-next-line no-useless-return
        }
        if (val.currency_id <= 0) {
          errors = true
          this.$bvToast.toast(this.$t('Eklediğiniz bazı ürünler için döviz seçimi yapmadınız.'), {
            title: this.$t('Dikkat'),
            variant: 'warning',
            solid: false,
          })
        }
        if (val.unit_id <= 0) {
          errors = true
          this.$bvToast.toast(this.$t('Eklediğiniz bazı ürünler için birim seçimi yapmadınız.'), {
            title: this.$t('Dikkat'),
            variant: 'warning',
            solid: false,
          })
        }
        if (val.unit_price === '') {
          errors = true
          this.$bvToast.toast(this.$t('Lütfen Tüm Ürünlerin Fiyat Alanını Doldurunuz'), {
            title: this.$t('Dikkat'),
            variant: 'warning',
            solid: false,
          })
          ifZeroId = true
          // eslint-disable-next-line no-useless-return
        }
      })
      if (errors) {
        return
      }
      if (ifZeroId === false) {
        if (this.orderData.id <= 0) {
          store.dispatch('app-order/addOrder', this.orderData)
          // eslint-disable-next-line no-unused-vars
            .then(response => {
              this.$router.push({ name: 'orders-list' })
              this.showSuccessMessage()
            })
            .catch(() => {
              this.showErrorMessage()
            })
        } else {
          store.dispatch('app-order/updateOrder', this.orderData)
          // eslint-disable-next-line no-unused-vars
            .then(response => {
              this.$router.push({ name: 'orders-list' })
              this.showSuccessMessage()
            })
            .catch(() => {
              this.showErrorMessage()
            })
        }
      }
    },
    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
    addProduct() {
      this.orderData.products.push(JSON.parse(JSON.stringify(this.blankProductData)))
    },
    productSelected(option, index) {
      store.dispatch('app/fetchProduct', { id: option.item.id, only_commercial_stores: 1 })
        .then(response => {
          const product = response.data.data
          const productData = this.orderData.products[index]
          productData.product_id = product.id
          productData.product_name = product.name
          productData.tax_id = product.tax.id//
          productData.tax_name = product.tax.name//
          productData.tax_rate = product.tax.tax_rate//
          productData.currency_id = product.currency_type
          productData.currency_name = product.currency.code
          //  productData.unit_price = product.price
          productData.unit_name = product.unit.name
          productData.list_price = product.price
          productData.list_price_currency = product.currency.code
          productData.stores = product.stock_data
          productData.unit_id = product.unit.id
          this.currencyOptions.forEach(val => {
            // eslint-disable-next-line eqeqeq
            if (productData.currency_id === val.value) {
              productData.exchange_rate = val.currency
            }
          })
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < productData.stores.length; i++) {
            productData.stores[i].store_name = `${productData.stores[i].store_name} (${productData.stores[i].stock}/${productData.stores[i].stock_reserved})`
          }
        })
    },
    customerSelected(option) {
      this.orderData.customer_name = option.item.name
      this.orderData.customer_id = option.item.id
      this.orderData.order_user = option.item.user_id
      this.orderData.customer_limit = option.item.limit
    },
    customerOnInputChanged(text) {
      if (text === '' || text === undefined) {
        return
      }
      store.dispatch('app/suggestCustomers', { name: text, limit: this.customerSuggest.limit, list_for: 'order' })
        .then(response => {
          this.customerSuggest.filteredCustomers = [{
            data: response.data.data,
          }]
        })
    },
    productOnInputChanged(text) {
      if (text === '' || text === undefined) {
        return
      }
      store.dispatch('app/suggestProducts', { name: text, limit: this.productSuggest.limit })
        .then(response => {
          this.productSuggest.filteredProducts = [{
            data: response.data.data,
          }]
        })
    },
  },
  setup() {
    const pageIsLoaded = ref(false)
    const currencyOptions = ref([])
    const blankProductData = {
      id: 0,
      product_id: 0,
      product_name: '',
      tax_id: 0,
      tax_name: '',
      currency_name: '',
      tax_rate: 0,
      quantity: 0,
      currency_id: 0,
      store_id: 0,
      unit_price: '',
      unit_name: '',
      unit_id: 0,
      description: '',
      list_price: 0,
      list_price_currency: '',
      exchange_rate: 0,
      is_deleted: false,
      stores: [],
    }
    const usersData = ref(null)
    store
      .dispatch('app/fetchUsers', {
        list_type: 'all',
        list_for: 'order',
      })
      .then(response => {
        const { users } = response.data.data
        const arr = []
        users.forEach(val => {
          arr.push({ label: `${val.name} (${val.email})`, value: val.id })
        })

        usersData.value = arr
      })
      .catch(() => {
      })
    const orderData = ref({
      id: 0,
      order_user: 0,
      representative_user: 0,
      order_number: '',
      customer_id: 0,
      customer_name: '',
      products: [

      ],
    })

    const PRODUCT_APP_STORE_MODULE_NAME = 'app-order'

    // Register module
    if (!store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) store.registerModule(PRODUCT_APP_STORE_MODULE_NAME, ordersStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) store.unregisterModule(PRODUCT_APP_STORE_MODULE_NAME)
    })

    const onSubmit = () => {
      store.dispatch('app-product/addProduct')
        .then(response => {
          router.push({ name: 'products-edit', params: { id: response.data.data.id } })
        })
    }
    store.dispatch('app/fetchCurrencyTypes', {})
      .then(response => {
        const arr = []
        response.data.data.forEach(val => arr.push({
          text: val.name, value: val.id, is_main: val.is_main, currency: val.currency,
        }))
        currencyOptions.value = arr
      })
    if (router.currentRoute.params.id !== undefined) {
      store.dispatch('app-order/fetchOrder', { id: router.currentRoute.params.id })
        .then(response => {
          orderData.value = response.data.data
          orderData.value.products.forEach(p => {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < p.stores.length; i++) {
              // eslint-disable-next-line no-param-reassign
              p.stores[i].store_name = `${p.stores[i].store_name} (${p.stores[i].stock}/${p.stores[i].stock_reserved})`
            }
          })
          pageIsLoaded.value = true
        })
        .catch(error => {
          if (error.response.status === 404) {
            alert('Error')
          }
        })
    } else {
      pageIsLoaded.value = true
    }
    const resetproductData = () => {

    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetproductData)
    return {
      orderData,
      onSubmit,
      currencyOptions,
      refFormObserver,
      getValidationState,
      pageIsLoaded,
      resetForm,
      blankProductData,
      usersData,
      priceInputConfig: {
        numeralDecimalScale: 4,
        numeral: true,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
